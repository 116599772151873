import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import Consultation from "../components/free-consultation"

const SecondPage = () => (
  <Layout>
    <Seo title="Accessory Dwelling Unit" />
    <Container className="singleCol">
      <h1>We Specialize in the Design of Accessory Dwelling Units</h1>
      <p>California state law now requires that city and county administrations allow Accessory Dwelling Units to be permitted. The term Accessory Dwelling Unit means self-contained, livable space which includes a kitchen and bathroom, and can mean a converted garage, wing of your house, or an entirely separate unit within your property line.  This may be valuable as an income generating space on Airbnb, mother-in-law unit, home to an onsite caregiver, or such uses as a guest cottage or home office. <Link to="/contact/">Contact us</Link>​ now to get started designing your ADU.  
      </p>
      <p>​Below are some sample projects we've completed:</p>
      <hr />
      <h2>Single Car Garage Converted to ADU</h2>
      <p>The clients needed a studio apartment for parent visits plus a home office. Their 10' x 35' detached garage was converted for both uses. </p>
    </Container>
    <Container className="text-center">
      <p>
        <StaticImage 
          src="../images/adu/9-garageconvert-entry_orig.jpg"
          alt="Front of Garage-turned ADU"
          placeholder="blurred"
        />
        
      </p>
    </Container>

    <Container className="singleCol">
    <hr />
      <h2>Downstairs Rec Room Becomes 1-Bedroom Apartment</h2>
      <p>Behind the garage and next to crawl space, clients had a long recreation room with its own bathroom and laundry. A kitchen was added and a bedroom was partitioned off to create an ADU, which now generates significant rental income.</p>
    </Container>
    <Container className="text-center">
      <p>
        <StaticImage 
          src="../images/adu/7-recrm-adu-kithen_orig.jpg"
          alt="Inside Kitchen of ADU"
          placeholder="blurred"
        />
      </p>
    </Container>

    <Container className="singleCol">
    <hr />
      <h2>ADU Over Garage</h2>
      <p>​ADU's can also be built as free-standing buildings on a homeowner's property. We proposed this building as a new two-car garage with a second-floor apartment.</p>
    </Container>
    <Container className="text-center">
      <p>
        <StaticImage 
          src="../images/adu/10-adu-overgarage.jpg"
          alt="ADU Over The Garage"
          placeholder="blurred"
        />
      </p>
    </Container>

    <Container className="singleCol">
    <hr />
      <h2>Bedroom Suite Converted To Junior ADU</h2>
      <p>​This client's existing bedroom already included a full bathroom and walk-in closet. The closet was converted to a new exterior entrance, plus a unit kitchen to create an ADU.</p>
    </Container>
    <Container className="text-center">
      <p>
        <StaticImage 
          src="../images/adu/bedroom-converted-to-adu.jpg"
          alt="Bedroom coverted to an ADU"
          placeholder="blurred"
        />
      </p>
      <hr className="singleCol mb-4" />
    </Container>
    <Consultation />
  </Layout>
)

export default SecondPage
